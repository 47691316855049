@font-face {
  font-family: 'MontserratRegular';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratItalic';
  src: url('./assets/fonts/Montserrat-Italic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBoldItalic';
  src: url('./assets/fonts/Montserrat-BoldItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

body {
  background: #303030;
  color: #f1f1f1;
  font: 16px MontserratRegular, sans-serif;

  button {
    text-transform: uppercase;
  }

  button.btn-danger{
    background: #EC243C;
  }

  a {
    color: #f1f1f1;

    &:hover {
      color: #EC243C;
    }
  }
}

.App {

  /*************** Header start ***************/
  &-header {
    background: #1B1C1E;
    overflow: hidden;

    &__inner {
      max-width: 1350px;
      margin: 0 auto;
      height: 50px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;

      .logo {
        cursor: pointer;
        height: 50px;
        width: 143px;
        background: url('./assets/images/logo.png') no-repeat 50% 50%;
        background-size: contain;
      }

      .main-menu,
      .register-menu {
        display: block;

        @media screen and (max-width: 992px) {
          display: none;
        }
      }

      .main-menu-bar {
        display: none;
        height: 50px;
        width: 50px;
        font-size: 30px;
        cursor: pointer;
        position: relative;
        text-align: center;
        line-height: 50px;

        @media screen and (max-width: 992px) {
          display: block;
        }
      }
    }
  }

  /*************** Header end   ***************/

  &-container {
    &__inner {
      max-width: 1350px;
      margin: 0 auto;
      padding: 30px;

      @media screen and (max-width: 768px) {
        padding: 30px 15px;
      }
    }

  }

  /*************** Footer start ***************/
  &-footer {
    background: #1B1C1E;
    overflow: hidden;

    &__inner {
      max-width: 1350px;
      margin: 0 auto;
      height: 45px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      color: #f1f1f1;

      &>.copyright {
        font: 12px MontserratRegular, sans-serif;
        line-height: 45px;
      }

      &>.social-icons {
        a {
          line-height: 45px;
          padding: 0 13px;
          color: #f1f1f1;
          font-size: 20px;

          &:hover {
            color: #EC243C;
            text-decoration: none;
          }
        }
      }

      @media screen and (max-width: 480px) {
        &>.social-icons>a {
          padding: 0 7px;
        }
      }
    }
  }

  /*************** Footer end   ***************/
}


/*************** Side menu start ***************/
.Side-menu-background {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(236, 36, 60, 0.15);
  display: block;
  width: 100%;
  height: 100%;
}

.Side-menu {
  width: 300px;
  padding: 25px 5px 0 25px;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: #1B1B1B;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1050;

  &__times {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 45px;
    color: #f1f1f1;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }

  &>h3 {
    color: #EC243C;
    font: 25px MontserratBold, sans-serif;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  @media screen and (max-width: 992px) {
    display: block;
  }
}

/*************** Side menu end   ***************/

/*************** Main menu start ***************/
.main-menu {
  &>a {
    font: 14px MontserratRegular, sans-serif;
    color: #f1f1f1;
    text-transform: uppercase;
    margin: 0 10px;
    line-height: 50px;

    &:hover {
      text-decoration: none;
      color: #EC243C;
    }
  }

  @media screen and (max-width: 992px) {
    &>a {
      display: block;
      font: 16px MontserratRegular, sans-serif;
      margin: 0 0 0 10px;
      color: #f1f1f1;
      line-height: 50px;
    }
  }
}

/*************** Main menu end   ***************/

/*************** Register menu start ***************/
.register-menu {
  &>a {
    font: 14px MontserratRegular, sans-serif;
    color: #f1f1f1;
    text-transform: uppercase;
    margin: 0 5px;
    line-height: 50px;

    &:hover {
      text-decoration: none;
      color: #EC243C;
    }
  }

  @media screen and (max-width: 992px) {
    &>span {
      display: none
    }

    &>a {
      display: block;
      font: 16px MontserratRegular, sans-serif;
      color: #f1f1f1;
      text-transform: uppercase;
      margin: 0 0 0 10px;
      line-height: 50px;

      &:hover {
        text-decoration: none;
        color: #EC243C;
      }
    }
  }
}

/*************** Register menu end   ***************/
